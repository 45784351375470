import React from 'react';
import SolutionCard from '@/components/modules/SolutionCard';
import ModuleWrapper from '@/components/modules/ModuleWrapper/ModuleWrapper';
import type { RelatedSolutionsTypes } from './RelatedSolutions.types';
import * as styles from './RelatedSolutions.module.scss';

const COLORS = ['#006585', '#0a6aa4', '#1f8de4', '#16aee6', '#34b8de', '#5595c3', '#7078ad', '#4c5488'];

export default function RelatedSolutions({
  solutions,
  title,
  top_spacing = 'md',
  bottom_spacing = 'md',
}: RelatedSolutionsTypes) {
  if (!solutions) return null;
  return (
    <section className={styles.wrapper}>
      <ModuleWrapper topSpacing={top_spacing} bottomSpacing={bottom_spacing}>
        {title && <h2 className={styles.heading}>{title}</h2>}
        <ul className={styles.gridList}>
          {solutions.map((card, index) => (
            <li key={card._uid}>
              <SolutionCard {...card} color={COLORS[index % COLORS.length]} />
            </li>
          ))}
        </ul>
      </ModuleWrapper>
    </section>
  );
}
