import React from 'react';
import Link from '@/components/elements/Link';
import { SolutionCardTypes } from './SolutionCard.types';
import * as styles from './SolutionCard.module.scss';

export default function SolutionCard({ title, description, link, link_label, color }: SolutionCardTypes) {
  const linkType = link?.linktype === 'url' ? 'external' : 'internal';
  return (
    <div className={`p-10 ${styles.wrapper}`} style={{ backgroundColor: color }}>
      <h3 className={`mb-2 ${styles.title}`}>{title}</h3>
      <p className={`mb-2 ${styles.excerpt}`}>{description}</p>
      {link?.cached_url && (
        <Link
          key={link.cached_url}
          type={linkType}
          href={link.cached_url}
          text={link_label}
          display_arrow
          arrow_direction="right"
        />
      )}
    </div>
  );
}
